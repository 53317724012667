// App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Page from "./Page";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Page />} />
        <Route path="/7D2W5JY" element={<Page />} />
        <Route path="/private" element={<Page />} />
      </Routes>
    </Router>
  );
}

export default App;
