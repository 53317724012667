import React, { useEffect, useState } from "react";
import "./Listing.css";
const Listing = ({ item }) => {
  const itemNameArray = item?.item_name?.split("|");
  const [itemType, setItemType] = useState("");
  const [itemName, setItemName] = useState("");
  useEffect(() => {
    if (itemNameArray?.length) setItemType(itemNameArray[0]);
    if (itemNameArray?.length) setItemName(itemNameArray[1]);
  }, [itemNameArray]);

  return (
    <div class="rame">
      <div class="item-wrapper quality-6">
        <div class="top flex">
          <div class="item-timer" style={{ padding: "40px 20px" }}>
            <img
              src="https://bitskins.com/assets/timer-7671d714.svg"
              class="img-timer svg-orange"
              alt=""
            />
            <span style={{ fontSize: "24px", color: "orange", opacity: "0.7" }}>
              Pending
            </span>
          </div>
        </div>
        <div class="item-price flex">
          <div class="price"></div>
          <div class="ref-price text-shadow text-cfi"></div>
        </div>
        <div class="ss-preview" style={{ top: "126px" }}>
          <div class="ss-preview-icons">
            <img
              src="https://bitskins.com/assets/dashed-square-a4f1db4f.svg"
              alt="screenshot"
            />
            <img
              src="https://bitskins.com/assets/mouse-click-bbf6e967.svg"
              alt="right click"
            />
          </div>
        </div>
        <div class="picture-wrapper">
          <div class="item-picture">
            <img
              src={item.item_picture}
              class="lazy-img loaded img-item"
              alt=""
              width="176px"
              height="132px"
            />
            <img
              src="https://ss.bitskins.com/41/4183e015fd972dce99643f36d8a00e25-back-thumbnail.webp?3"
              class="lazy-img loaded img-item"
              alt=""
              style={{ display: "none" }}
            />
          </div>
        </div>
        <div class="item-name">
          <div
            class={` ${
              itemType.includes("Souvenir") ? "orange" : "item-category star"
            }`}
          >
            {itemType}
          </div>
          <div class="value">
            <span>{itemName}</span>
          </div>
          <div class="item-details item-text" quality="Covert" type="Knife">
            <span class="item-exterior">{item.item_exterior}</span>
            <span class="delimiter"> </span>
            <span class="item-paint-seed"></span>
          </div>
        </div>
        <div class="item-float">
          <div class="float-bar">
            <div class="out out-min" style={{ width: "0%" }}></div>
            <div class="fn"></div>
            <div class="mw"></div>
            <div class="ft"></div>
            <div class="ww"></div>
            <div class="bs"></div>
            <div class="out out-max" style={{ width: "50%" }}></div>
            <span
              class="caret"
              style={{
                left: `${item.exterior === "Factory New" ? "5.95%" : "13%"}`,
              }}
            ></span>
          </div>

          <div class="float-value item-text"></div>
        </div>
        <div class="item-extras"></div>
        <div class="btns-row">
          <a href={`https://steamcommunity.com/tradeoffer/${item.trade_id}/`}>
            <button
              class="btn btn-primary btn-cta"
              style={{ backgroundColor: "orange", opacity: "0.9" }}
            >
              Pending
              <img
                style={{ marginLeft: "20px" }}
                src="data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='26.458'%20height='19.399'%20viewBox='0%200%2026.458%2019.399'%3e%3cg%20id='Layer_24'%20data-name='Layer%2024'%20transform='translate(-1%20-5.004)'%3e%3cpath%20id='Path_16892'%20data-name='Path%2016892'%20d='M27.458%2011.174a.882.882.0%2001-.256.626L21.91%2017.092a.882.882.0%2001-1.508-.626V13.82H12.465a.882.882.0%2001-.882-.882V9.41a.882.882.0%2001.882-.882H20.4V5.882a.882.882.0%20011.508-.617L27.2%2010.557A.882.882.0%200127.458%2011.174zm-11.465%204.41H8.055V12.938a.887.887.0%2000-1.508-.626L1.256%2017.6a.894.894.0%20000%201.252l5.292%205.292a.882.882.0%2000.626.256.741.741.0%2000.335-.071.882.882.0%2000.547-.811V20.875h7.937a.882.882.0%2000.882-.882V16.466A.882.882.0%200015.993%2015.584z'%20fill='%23fff'/%3e%3c/g%3e%3c/svg%3e"
                alt="trade"
              />
            </button>
          </a>
        </div>
      </div>
      <div class="item-menu"></div>
    </div>
  );
};

export default Listing;
