import React, { useEffect } from "react";
import Listing from "./Listing";

const Main = ({ items }) => {
  return (
    <>
      <div class="private-offer">
        <p
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {" "}
          <div class="icon" style={{ marginRight: "10px" }}>
            <img
              src="data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='20.696'%20height='24.548'%20viewBox='0%200%2020.696%2024.548'%3e%3cg%20transform='translate(0%200)'%3e%3cpath%20d='M190.19%202.919%20180.561.031a.719.719.0%2000-.413.0l-9.629%202.889a.719.719.0%2000-.513.689V14.2a6.841%206.841.0%20001.658%204.192%2017.135%2017.135.0%20003.454%203.2%2030.984%2030.984.0%20004.942%202.894.718.718.0%2000.588.0%2030.984%2030.984.0%20004.942-2.894%2017.138%2017.138.0%20003.454-3.2A6.841%206.841.0%2000190.7%2014.2V3.608A.719.719.0%2000190.19%202.919zM189.264%2014.2c0%202.438-2.446%204.724-4.5%206.213a30.458%2030.458.0%2001-4.411%202.622%2030.458%2030.458.0%2001-4.411-2.622c-2.052-1.489-4.5-3.775-4.5-6.213V4.143l8.91-2.673%208.91%202.673z'%20transform='translate(-170.006%200)'%20fill='%23af4aaf'/%3e%3c/g%3e%3cg%20transform='translate(7.819%207.763)'%3e%3cg%20transform='translate(0)'%3e%3cpath%20d='M400.316%20273a2.529%202.529.0%2000-1.37%204.655v3a1.367%201.367.0%20002.734.0v-3A2.529%202.529.0%2000400.316%20273z'%20transform='translate(-397.787%20-273.004)'%20fill='%23af4aaf'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e"
              alt="Private offer"
            />
          </div>
          <span class="title">Private offer</span>{" "}
          <span style={{ fontSize: "18px", marginTop: "5px" }}>
            This offer is only visible to people who have a link to it
          </span>
        </p>
      </div>
      <div
        style={{
          display: "flex",
          gap: "70px",
          paddingLeft: "100px",
          paddingTop: "80px",
        }}
      >
        {items.map((item) => (
          <Listing item={item} />
        ))}
      </div>
    </>
  );
};

export default Main;
