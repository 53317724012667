import React from "react";

const ActiveTradeImage = ({ source }) => {
  return (
    <div class="item-hover">
      <div class="item-picture">
        <img
          src={source}
          class="lazy-img loaded img-item"
          alt=""
          width="48"
          height="36"
        />
      </div>
      <div class="content"></div>
      <div class="content">
        <div class="tooltip-content">
          <div class="item-hover-tooltip">
            <div class="item-picture">
              <img
                src="https://steamcommunity-a.akamaihd.net/economy/image/class/730/3608083945"
                class="lazy-img loaded img-item"
                alt=""
              />
            </div>
            <div class="info">
              <div class="item-info-name">
                <div class="item-name">
                  <div class="item-category">Sticker</div>
                  <div class="value">
                    <span>Gold Web </span>
                  </div>
                  <div class="item-details item-text"></div>
                </div>
              </div>
            </div>
            <div class="price">
              <span class="price-text">Price</span>
              <div class="amount">$0.23</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveTradeImage;
